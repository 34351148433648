import React from "react"
import BlogLayout from "../../components/Blog/BlogLayout"
import BlogTwo from "../../components/Blog/BlogsList/BlogTwo"
const BlogContent = () => {
  return (
    <BlogLayout>
      <BlogTwo />
    </BlogLayout>
  )
}

export default BlogContent
